import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import PartnerHeader from "./partnerHeader";
import Footer from "@components/footer";

const PartnerLayout = ({ children, pageData }) => {
  // console.log(pageData);

  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `);

  return (
    <div>
      <PartnerHeader
        // siteTitle={data.site.siteMetadata?.title || `Avert`}
        pageData={pageData}
      />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

PartnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PartnerLayout;

import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import TopNav from "@components/topNav";

const PartnerHeader = ({ pageData }) => {
  //   console.log(pageData);

  const theme = pageData.partnerThemeColor;
  const heroHeading = pageData.partnerName;
  const themeTitleColor = pageData.partnerThemeTitleColor;

  const heroText = pageData.partnerHeroText;
  const heroImg = getImage(
    pageData.partnerHeroImage && pageData.partnerHeroImage.file
  );
  const heroImgAlt =
    pageData.partnerHeroImage && pageData.partnerHeroImage.alternativeText;

  const themeColor = () => {
    switch (theme) {
      case "sunset":
        return "#C12026";

      case "marine":
        return "#419B87";

      case "marineDark":
        return "#2B7363";

      case "fossil":
        return "#555555";

      case "orange":
        return "#FF8500";

      case "yellowAvert":
        return "#FBD500";

      case "lime":
        return "#A5CF07";

      case "purpleAvert":
        return "#D41AD0";

      case "midBlue":
        return "#17607D";

      case "lightBlue":
        return "#4FCDFF";

      case "boostPink":
        return "#BD7AB2";

      default:
        return "#C12026";
    }
  };

  return (
    <header>
      <div className={"relative bg-" + theme}>
        <div className="max-w-6xl mx-auto">
          <div
            className={
              "relative z-10 pb-8 bg-" +
              theme +
              " sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
            }
          >
            <svg
              className={
                "hidden md:block sm:hidden pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-" +
                theme +
                " transform translate-x-1/2"
              }
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill={themeColor()}
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1
                  className={
                    themeTitleColor
                      ? "text-4xl tracking-loose font-bold text-" +
                        themeTitleColor +
                        " sm:text-5xl md:text-5xl font-heading"
                      : "text-4xl tracking-loose font-bold text-yellowAvert sm:text-5xl md:text-5xl font-heading"
                  }
                >
                  {heroHeading}
                </h1>
                <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  <div> {heroText}</div>
                  {/* <div className="pt-12"> {heroText}</div> */}
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <GatsbyImage
            image={heroImg}
            alt={heroImgAlt}
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          />
        </div>
      </div>
    </header>
  );
};

PartnerHeader.propTypes = {
  siteTitle: PropTypes.string,
};

PartnerHeader.defaultProps = {
  siteTitle: `Avert`,
};

export default PartnerHeader;

import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PartnerLayout from "@components/partnerLayout";
import Seo from "@components/seo";
import ReactMarkdown from "react-markdown";
import Quote from "@components/quote";

const PartnerPage = ({ data }) => {
  const pageData = data.strapiPartner;

  // console.log(pageData);

  return (
    <PartnerLayout pageData={pageData}>
      <Seo
        title={pageData.partnerName}
        description={pageData.partnerHeroText}
        featuredImage={pageData.partnerHeroImage.file.url}
      />

      <div className="">
        <div className="relative py-12 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              {/* Heading about partnership */}
              <h1>
                <span className="mt-2 pb-4 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                  {pageData.partnerAboutpartnershipHeading}
                </span>
              </h1>

              <span className="mt-0 prose prose-lg text-fossil mx-auto">
                <ReactMarkdown>
                  {pageData.partnerAboutPartnershipText}
                </ReactMarkdown>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* quote  */}

      {pageData.partnerQuoteName ? (
        <Quote
          quoteName={pageData.partnerQuoteName}
          quoteText={pageData.partnerQuoteText}
          quoteTitle={pageData.partnerQuoteTitle}
          themeColor={"marineDark"}
          quoteColor={"lime"}
          quoteTextColor={"white"}
        />
      ) : null}

      {/* About partner text */}

      <div className="">
        <div className="relative py-6 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              {/* Heading about partnership */}
              <h1>
                <span className="mt-0 pb-4 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl font-heading">
                  {pageData.partnerAboutHeading}
                </span>
              </h1>

              <span className="mt-0 prose prose-lg text-fossil mx-auto">
                <ReactMarkdown>{pageData.partnerAbouttext}</ReactMarkdown>
              </span>
            </div>
          </div>
        </div>
      </div>
      {pageData.partnerLogo ? (
        <>
          <div className="text-lg max-w-prose mx-auto">
            <figure>
              <GatsbyImage
                className="w-full rounded-lg"
                image={getImage(pageData.partnerLogo.file)}
                alt={pageData.partnerLogo.alternativeText}
              />
            </figure>
          </div>
          <div className="flex pb-12 pt-3"></div>
        </>
      ) : null}
    </PartnerLayout>
  );
};

export const query = graphql`
  query ($pageSlug: String!) {
    strapiPartner(partnerSlug: { eq: $pageSlug }) {
      id
      partnerAboutHeading
      partnerAboutPartnershipText
      partnerAboutpartnershipHeading
      partnerAbouttext
      partnerHeroText
      partnerName
      partnerQuoteName
      partnerQuoteText
      partnerQuoteTitle
      partnerSlug
      partnerThemeColor
      partnerThemeTitleColor
      partnerHeroImage {
        alternativeText
        file {
          url
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: AUTO
            )
          }
        }
      }
      partnerLogo {
        alternativeText
        file {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: AUTO
            )
          }
        }
      }
    }
  }
`;

export default PartnerPage;
